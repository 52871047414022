import React from 'react';
import styled from 'styled-components';
import {Button, Input} from 'antd';

import * as AppContext from '../../AppContext';
import {Avatar, LockOutlined, Facebook, Google} from '../../Widgets/Icon';

function ForgetPasswordForm(props) {
  const app = React.useContext(AppContext.Context);
  const {uiState} = props;
  const [values, setValues] = React.useState({
    username: '',
    new_password: '',
    confirm_password: '',
  });
  const [message, setMessage] = React.useState('');

  const getValue = (key) => values[key];
  const onValueChange = (key) => (e) => {
    setValues({...values, [key]: e.target.value});
  };

  const formValidation = () => {
    if (uiState === 'send_email') {
      if (!values.username) {
        alert('帳號不得為空。');
        return false;
      }
    }

    if (uiState === 'set_new_password') {
      if (!values.new_password) {
        alert('新密碼不得為空。');
        return false;
      } else if (!values.confirm_password) {
        alert('確認密碼不得為空。');
        return false;
      } else if (values.new_password.length < 8) {
        alert('新密碼需 8 碼以上。');
        return false;
      } else if (values.new_password !== values.confirm_password) {
        alert('新密碼、確認密碼需相同。');
        return false;
      }
    }

    return true;
  };

  const onSendEmail = async () => {
    if (!formValidation()) {
      return;
    }

    app.actions.setLoading(true);
    try {
      let result = await app.actions.staffForgetPasswordByEmail(
        values.username,
      );
      setMessage(
        `忘記密碼信件已寄送至 ${result.email}，請開啟信件進行重設。若您未收到信件，請與系統人員聯絡。`,
      );
    } catch (err) {
      console.warn(err);
    }
    app.actions.setLoading(false);
  };

  const onSetNewPassword = async () => {
    if (!formValidation()) {
      return;
    }

    app.actions.setLoading(true);
    try {
      let result = await app.actions.staffForgetPassword(values.new_password);
      setMessage(`新密碼設定成功，請用新密碼登入。：）`);
    } catch (err) {
      console.warn(err);
    }

    app.actions.setLoading(false);
  };

  return (
    <Wrapper>
      <form>
        <header>
          <img
            alt="logo"
            src="/images/logo.png"
            style={{width: 251, height: 67}}
          />
        </header>

        {uiState === 'send_email' && (
          <>
            <label style={{marginBottom: 20}}>
              請輸入您的員工帳號，以重設密碼。
            </label>

            <label>帳號</label>
            <Input
              placeholder="請輸入員工帳號"
              prefix={<Avatar />}
              style={{height: 40}}
              value={getValue('username')}
              onChange={onValueChange('username')}
            />

            <label style={{marginTop: 25, marginBottom: 25}}>{message}</label>

            <Button
              type="primary"
              size="large"
              style={{borderRadius: 4, fontSize: 14}}
              onClick={() => onSendEmail()}>
              送出
            </Button>
          </>
        )}

        {uiState === 'set_new_password' && (
          <>
            <label style={{marginBottom: 20}}>請設定新密碼及確認新密碼。</label>

            <Input.Password
              prefix={<LockOutlined color="#808080" />}
              placeholder={'請設定密碼'}
              style={{marginBottom: 15, height: 40}}
              value={getValue('new_password')}
              onChange={onValueChange('new_password')}
            />

            <Input.Password
              prefix={<LockOutlined color="#808080" />}
              placeholder={'請確認密碼'}
              style={{height: 40}}
              value={getValue('confirm_password')}
              onChange={onValueChange('confirm_password')}
            />

            <label style={{marginTop: 25, marginBottom: 25}}>{message}</label>

            <Button
              type="primary"
              size="large"
              style={{borderRadius: 4, fontSize: 14}}
              onClick={() => onSetNewPassword()}>
              送出
            </Button>
          </>
        )}
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;

  & > form {
    max-width: 350px;
    width: 100%;

    & > header {
      margin-bottom: 45px;
      display: flex;
      align-self: center;
      align-items: center;
    }

    margin: 20px auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    & > label {
      margin: 0px 10px 10px 10px;
      font-size: 14px;
      color: #6b6c6f;
      letter-spacing: 0.4px;
    }

    & > button {
      cursor: pointer;
      width: 100%;
      height: 40px;
      border: 0 solid #d9d9d9;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      :focus {
        outline: none;
      }
    }
  }
`;

export default ForgetPasswordForm;
