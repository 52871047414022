import React from 'react';
import styled from 'styled-components';
import {Button, Modal, Input, Checkbox} from 'antd';
import {navigate} from 'gatsby';

import * as AppContext from '../../AppContext';
import {Avatar, LockOutlined} from '../../Widgets/Icon';
import ForgetPasswordForm from './StaffForgetPasswordForm';

const queryString = require('query-string');

function StaffLandingPage(props) {
  const app = React.useContext(AppContext.Context);
  const [values, setValues] = React.useState({
    username: app.actions.staffGetUsername() || '',
    password: '',
    checked: false,
  });
  const [modal, setModal] = React.useState({type: null});

  const getValue = (key) => values[key];
  const onValueChange = (key) => (e) => {
    if (e.target.type === 'checkbox') {
      setValues({...values, [key]: e.target.checked});
    } else {
      setValues({...values, [key]: e.target.value});
    }
  };

  function formValidation() {
    if (!values.username || !values.password) {
      alert('員工代碼及密碼不得為空。');
      return false;
    }
    return true;
  }

  async function onLogin() {
    if (!formValidation()) {
      return;
    }

    app.actions.setLoading(true);
    try {
      const {username, password, checked} = values;
      await app.actions.staffLogin({username, password});
      app.actions.staffSetUsername(checked);
      navigate('/member');
    } catch (err) {
      const json = await err.json();
      if (err.status === 400 && json.code === 'login_failure_error') {
        alert('員工代碼或密碼不正確。');
      }
      if (err.status === 404 && json.code === 'user_not_found') {
        alert('尚未開通該位員工代碼。');
      }
    }
    app.actions.setLoading(false);
  }

  React.useEffect(() => {
    if (typeof window !== undefined) {
      const entries = window.location.href;
      const params = queryString.parse(window.location.search);
      const token = params.token;

      if (token && !modal.type) {
        window.localStorage.setItem('token', token);
        window.history.replaceState({}, document.title, entries.split('?')[0]);
        setModal({type: 'forget_password', uiState: 'set_new_password'});
      }
    }
  }, [modal.type, app.actions]);

  return (
    <Wrapper>
      <form>
        <header>
          <img
            alt="logo"
            src={'/images/logo.png'}
            style={{width: 251, height: 67}}
          />
        </header>

        <label>帳號</label>
        <Input
          prefix={<Avatar />}
          placeholder={'請輸入員工帳號'}
          style={{marginBottom: 17, height: 40}}
          value={getValue('username')}
          onChange={onValueChange('username')}
        />

        <label>密碼</label>
        <Input.Password
          prefix={<LockOutlined color="#808080" />}
          placeholder={'請輸入密碼'}
          style={{marginBottom: 30, height: 40}}
          value={getValue('password')}
          onChange={onValueChange('password')}
        />

        <Button
          type="primary"
          size="large"
          onClick={() => onLogin()}
          style={{borderRadius: 4, fontSize: 14}}>
          登入
        </Button>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 15,
            marginBottom: 60,
          }}>
          <Checkbox
            checked={getValue('checked')}
            onChange={onValueChange('checked')}
            style={{color: '#6B6C6F', letterSpacing: 0.4}}>
            記住我
          </Checkbox>

          <div style={{flex: 1}}></div>

          <Button
            type="text"
            style={{color: '#6B6C6F', letterSpacing: 0.4}}
            onClick={() =>
              setModal({type: 'forget_password', uiState: 'send_email'})
            }>
            忘記密碼?
          </Button>
        </div>
      </form>

      <Modal
        key={modal.type}
        title={null}
        footer={null}
        width={600}
        bodyStyle={{padding: 0}}
        visible={!!modal.type}
        onCancel={() => setModal({type: null})}>
        {modal.type === 'forget_password' && (
          <ForgetPasswordForm uiState={modal.uiState} />
        )}
      </Modal>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;

  & > form {
    transform: translateY(-75px);
    max-width: 350px;
    width: 100%;

    & > header {
      margin-bottom: 45px;
      display: flex;
      align-self: center;
      align-items: center;
    }

    margin: 20px auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    & > label {
      margin: 0px 10px 10px 10px;
      font-size: 14px;
      color: #6b6c6f;
      letter-spacing: 0.4px;
    }

    & > button {
      cursor: pointer;
      width: 100%;
      height: 40px;
      border: 0 solid #d9d9d9;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      :focus {
        outline: none;
      }
    }
  }
`;

export default StaffLandingPage;
